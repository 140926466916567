import logo from "./logo.svg";
import "./App.css";

const team = [
  {
    fullname: "Guillaume Vacelet",
    firstname: "Guillaume",
    lastname: "Vacelet",
    github: "https://github.com/Guillaume-Vacelet",
    linkedin: "https://www.linkedin.com/in/guillaume-vacelet",
    picture: "/members/blindspot_guillaume_vacelet.webp",
  },
  {
    fullname: "Jinyang Zou",
    firstname: "Jinyang",
    lastname: "Zou",
    github: "https://github.com/ArtElect",
    linkedin: "https://www.linkedin.com/in/jinyangzou-9357b7153/",
    picture: "/members/blindspot_jinyang_zou.webp",
  },
  {
    fullname: "Johann Perret",
    firstname: "Johann",
    lastname: "Perret",
    github: "https://github.com/Johann-P",
    linkedin: "https://www.linkedin.com/in/johann-perret-342504175",
    picture: "/members/blindspot_johann_perret.webp",
  },
  {
    fullname: "Léa Boulay",
    firstname: "Léa",
    lastname: "Boulay",
    github: "https://github.com/LeaBoulay",
    linkedin: "https://www.linkedin.com/in/l%C3%A9a-boulay-15a832175/",
    picture: "/members/blindspot_lea_boulay.webp",
  },
  {
    fullname: "Mathieu Herrmann",
    firstname: "Mathieu",
    lastname: "Herrmann",
    github: "https://github.com/MathieuHerrmann",
    linkedin: "https://www.linkedin.com/in/mathieu-herrmann-795697193/",
    picture: "/members/blindspot_mathieu_herrmann.webp",
  },
  {
    fullname: "Quentin Colliez",
    firstname: "Quentin",
    lastname: "Colliez",
    github: "https://github.com/QuentinC974",
    linkedin: "https://www.linkedin.com/in/quentin-colliez/",
    picture: "/members/blindspot_quentin_colliez.webp",
  },
  {
    fullname: "Theo Mazars",
    firstname: "Theo",
    lastname: "Mazars",
    github: "https://github.com/theo-mazars",
    linkedin: "https://www.linkedin.com/in/theo-mazars/",
    picture: "/members/blindspot_theo_mazars.webp",
  },
  {
    fullname: "Zacharie Azoulai-Minassian",
    firstname: "Zacharie",
    lastname: "Azoulai-Minassian",
    github: "",
    linkedin: "https://www.linkedin.com/in/zacharie-azoulai-minassian",
    picture: "/members/blindspot_zacharie_azoulai-minassian.webp",
  },
];

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="Outro">
          <p>
            C'est avec regret que nous vous informons que le projet BlindSpot,
            développé depuis deux ans par notre équipe, touche à sa fin. Malgré
            tous nos efforts, et le temps passé sur l'élaboration de notre
            prototype, nous avons décidé de stopper le développement du projet.
          </p>
          <p>
            Cependant, nous avons décidé de rendre notre travail open-source
            afin de permettre à la communauté de bénéficier de notre avancement.
            Nous sommes fiers de ce que nous avons accompli et espérons que
            notre projet pourra inspirer d'autres personnes à poursuivre des
            idées similaires.
          </p>
          <p>
            Nous tenons à remercier tous ceux qui nous ont soutenus pendant ces
            deux années,{" "}
            <a href="https://epitech.eu" target="_blank" rel="noreferrer">
              EPITECH
            </a>{" "}
            qui nous a accompagné sur toute la durée du projet, ainsi que nos
            testeurs qui nous ont fait part de leurs retours et de leur
            enthousiasme pour notre projet.
          </p>
          <p>
            Nous restons à votre disposition pour toute question ou demande
            d'information supplémentaire.
          </p>
          <p>Vive la science 🖖</p>
          <p>
            <b>L'équipe BlindSpot.</b>
          </p>
        </div>
        <div className="Team">
          {team.map((member) => (
            <div className="Member">
              <img src={member.picture} alt={member.fullname} />
              <div className="MemberInfo">
                <div className="MemberName">
                  <span>{member.firstname}</span>
                  <br />
                  <span>{member.lastname}</span>
                </div>
                <div className="MemberLinks">
                  {/* {member.github && (
                    <a href={member.github} target="_blank" rel="noreferrer">
                      GitHub
                    </a>
                  )}
                  {member.github && member.linkedin && <br />} */}
                  {member.linkedin && (
                    <a href={member.linkedin} target="_blank" rel="noreferrer">
                      LinkedIn
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </header>
    </div>
  );
}

export default App;
